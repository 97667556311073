











































import { defineComponent, PropType } from '@vue/composition-api'

import MagazineCard, {
  MagazineCard as MagazineCardType,
} from '@/components/magazine/Card.vue'

interface MagazineArticle {
  htmltext: string
  post: MagazineCardType
  video?: string
}

interface MagazineArticles {
  title: string
  items: MagazineArticle[]
}

export default defineComponent({
  name: 'MagazineArticles',
  components: {
    MagazineCard,
  },
  props: {
    content: {
      type: Object as PropType<MagazineArticles>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})

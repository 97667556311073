






















import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import gsap from 'gsap'
import CustomEase from 'gsap/dist/CustomEase.js'

gsap.registerPlugin(CustomEase)

import MagazineArticles from '@/components/magazine/Articles.vue'
import MagazineCategories from '@/components/magazine/Categories.vue'
import MagazineLandingHero from '@/components/magazine/LandingHero.vue'
import MagazineStories from '@/components/magazine/Stories.vue'
import CardSlider from '@/components/flexible/CardSlider.vue'

export default defineComponent({
  name: 'MagazineLanding',
  components: {
    MagazineArticles,
    MagazineCategories,
    MagazineLandingHero,
    MagazineStories,
    CardSlider,
  },
  props: {},

  setup(_props) {
    const { content } = useGetters(['content'])
    const rootElRef = ref<HTMLElement | null>(null)

    onMounted(() => {
      rootElRef.value?.classList.remove('before-onboard')
    })

    return {
      content,
      rootElRef,
    }
  },
})

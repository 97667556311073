













import { defineComponent, PropType } from '@vue/composition-api'
import type { FlexibleSlider as Slider } from '@/inc/types'

import MagazineCard, {
  MagazineCard as Card,
} from '@/components/magazine/Card.vue'
import FlexibleSlider from '@/components/flexible/Slider.vue'

export default defineComponent({
  name: 'FlexibleCardSlider',
  props: {
    content: {
      type: Object as PropType<Slider<Card>>,
      required: true,
    },
  },
  components: {
    MagazineCard,
    FlexibleSlider,
  },

  setup() {
    return {}
  },
})
